/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.
NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC
employees, managers or contractors who have executed Confidentiality and
Non-disclosure agreements explicitly covering such access.
The copyright notice above does not evidence any actual or intended
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "node-fetch";
import LandingPageHeader from "../components/LandingPageHeader.js";
import PageFooter from "../components/PageFooter";
import "./Landing.css";
import SpotsPopup from "../components/SpotsPopup.js";

/*
  Landing.js:
  - Path: /
  - Description: This is the landing page for users
*/

const Landing = () => {
  const SURVEY_PATH = "/qualification-survey";
  const SPOTS_API_PATH = "/spots-left";
  const MIN_SPOTS = 0;

  const history = useHistory();
  const [spots, setSpots] = useState(null);
  const [popUp, setPopup] = useState(false);
  const [qualifiedButton, setQualifiedButton] = useState(true);

  useEffect(() => {
    let endpoint = new URL(process.env.REACT_APP_API_BASE_URL + SPOTS_API_PATH);

    fetch(endpoint, { method: "get" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const spotsLeft = data.data;
        setSpots(spotsLeft);
        if (spotsLeft <= MIN_SPOTS) {
          setQualifiedButton(false);
        }
      });
  }, []);

  const onLogin = () => {
    console.log("Logging out");
    history.push({
      pathname: "/login",
    });
  };

  const onCheckQualification = () => {
    if (qualifiedButton) {
      console.log("Redirecting to survey");
      history.push({
        pathname: SURVEY_PATH,
      });
    } else {
      setPopup(true);
    }
  };

  let spotsText;
  if (qualifiedButton) {
    spotsText = (
      <h3>
        Only <span>{spots}</span> Spots Left
      </h3>
    );
  } else {
    spotsText = <h3>No Spots Available</h3>;
  }

  return (
    <div className="body">
      <LandingPageHeader shadow text="Log in" onButtonClick={onLogin} landing onLandingbtnClick={onCheckQualification} landingBtnText={qualifiedButton ? "See If You Qualify" : "Additional Info"}/>
      <div className="landing-main pb-30">
        <SpotsPopup trigger={popUp} setTrigger={setPopup} />
        <div className="landing-banner">
          <div className="landing-gradient"></div>
          <div className="landing-info">
            <h1>
              Sleep Better<br/>Feel Better
            </h1>
            <br />
            <h2>
              Take a short survey to see if you qualify for our at-home sleep
              apnea test. Get the sleep insights you've always wanted!
            </h2>
      
            <button onClick={onCheckQualification}>
              {qualifiedButton ? "See If You Qualify" : "Additional Info"}
            </button>
            {spotsText}
          </div>
          <div className="landing-bottom-gradient"></div>
        </div>
        <div className="info-section">
          <div className="info-container">
            <p className="info-text">With our sleep test, you’ll be able to:</p>
            <div className="info-row">
              <div className="info-col">
                <h2>01.</h2>
                <p className="info-col-des">
                  Test for Obstructive Sleep Apnea at home
                </p>
              </div>
              <div className="info-col">
                <h2>02.</h2>
                <p className="info-col-des">
                  Receive a comprehensive report about your sleep health
                </p>
              </div>
              <div className="info-col">
                <h2>03.</h2>
                <p className="info-col-des">
                  Get diagnosis and treatment options for Obstructive Sleep
                  Apnea
                </p>
              </div>
            </div>
          </div>
          <p className="info-description">
            Disclaimer: This study uses investigational devices and is for
            research purposes only.
          </p>
        </div>
      </div>
      <div className="landing-footer-section">
        <PageFooter />
      </div>
    </div>
  );
};

export default Landing;
